/**
 * return the black/white color based on the contrast.
 * @param {*} bgColor background color given to header
 * @return {string} return black when contrast is high and vice versa
 */
export function getContrastingColor(bgColor) {
  const c = bgColor?.substring(1);
  const rgb = parseInt(c, 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  if (luma < 127) {
    return '#ffffff';
  } else {
    return '#000000';
  }
}
